.title{
	margin: 0;
	border: 1px solid var(--primary-color);
	padding: 10px 20px;
	border-radius: var(--radius);
}

.content{
	gap:10px;
	position: relative;
}

.add{
	border: 1px solid var(--primary-color);
	padding: 10px 30px;
	border-radius: var(--radius);
	background-color: transparent;
	cursor: pointer;
	transition: 300ms ease-out;
	font-weight: bold;
}

.add:hover{
	background-color: var(--primary-color);
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.form_container{
	overflow-y: auto;
}