.header-logo{
	height: auto;
	width: 100px ;
}
.header{
	border: 1px solid var(--primary-color);
	border-radius: var(--radius);
	width: 100%;
	padding-top:10px;
	padding-bottom: 6px;
}