.main{
	align-self: flex-start;
	width: 100%;
	height: calc(100% - 40px);
	margin: 10px;
}
.left{
	align-self: flex-start;
}
.box{
	width: 95%;
	border: 1px solid var(--primary-color);
	border-radius: var(--radius);
	padding: 10px;
	overflow-x: hidden ;
	overflow-y: auto;
	height: 95%;
}
.container{
	margin: 10px 0px;
	display: flex;
	height: 100%;
}
