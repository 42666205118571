.logo{
	height: auto;
	width:200px;
}
.sub-input{
	border: none;
	outline: none;
	width: 220px;
}
.input{
	border:1px solid var(--primary-color);
	padding: 12px 10px;
	width: 240px;
	border-radius: var(--radius);
	transition: 300ms ease-out;
	cursor: text;
}
.input:hover{
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.icon{
	margin-left: auto;
}
.btn{
	border:1px solid var(--primary-color);
	border-radius: var(--radius);
	padding: 10px 25px;
	transition: 300ms ease-out;
}
.btn:hover{
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.error{
	background-color: #ffa8a8;
    color: red;
    border: 1px solid red;
    border-radius: var(--radius);
    padding: 0 70px;
}