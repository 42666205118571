@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --btn-color:#f5f5f5;

  --primary-color: #e3e3e3;
  --radius: 5px;

}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5; 
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: grey; 
}