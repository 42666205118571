body{
	margin: 0;
	font-family: var(--font-base);
	overflow-x:hidden ;
}
.app-flex{
	display: flex;
	justify-content: center;
	align-items: center;
}
.full-v{
	height: 100%;
	width: 100vw
}
.full{
	height: 100%;
	width: 100%;
}
.full-w{
	width: 100%;
}
.wrap{
	flex-direction: column;
	gap: 15px;
}
.space{
	flex-direction: column;
	gap:8px;
}
.pointer{
	cursor: pointer;
}
.wrapper{
	display: flex;
	align-items: center;
	flex-direction: column;
}

.contract-font{
	font-family: Arial;
}
.f-column{
	flex-direction: column;
	gap: 15px;
}