.header-details{
	align-self: flex-start;
}

.split{
	display: flex;
	flex-direction: row;
	gap:10px;
	
}

.nom{
	padding:8px 12px ;
}
.add-icon{
	font-size: 1.2rem;
}

.msg{
	width:100%;
}

.error-msg{
	width:100%;
	background-color: #ffa8a8;
    color: red;
    border: 1px solid red;
    border-radius: var(--radius);
    padding: 0 70px;
    padding: 15px 0px;
}

.success-msg{
	width:100%;
	background-color: #00800066;
    color: green;
    border: 1px solid green;
    border-radius: var(--radius);
    padding: 0 70px;
    padding: 15px 0px;
}