.main-sidebar{
	width:240px;
	padding: 0px 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap:10px;
	margin-left: 20px;
}
.abstract{
	text-decoration: none;
	color: black;
	gap:10px;
	padding: 15px;
	width: 193px;
	justify-content: flex-start;
	transition: 200ms ease-in-out;
	font-weight: bold;
}
.abstract:hover{
	background-color: var(--btn-color);
	border-radius: var(--radius);
}
.side-icon{
	font-size: 20px;
}
.isActive{
	background-color: var(--btn-color);
	border-radius: var(--radius);
}
.shrink{
	width:90px !important;
}
.small{
	width:20px !important;
}

@media screen and (max-width: 800px){
	.shrink{
		width:60px !important;
	}
	.main-sidebar{
		margin-left: 0;
	}
}

.toleft{
	justify-content: flex-start !important;
}
.tab{
	text-align: center;
}