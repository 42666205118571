table{
    border:1px solid var(--primary-color);
    padding: 0;
    border-spacing: 0;
    border-radius: var(--radius);
    border-collapse: collapse;
    width: 100%;
}
tr, td{
    margin: 0;
    padding: 5px 10px;
}
tr:nth-of-type(even){
    background-color: var(--btn-color);
}
tr:first-child td:last-child{
    border-top-right-radius: 3px;
}
tr:first-child td:first-child {
    border-top-left-radius: 3px;
}
tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
}
tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
}

tr:not(:last-child){
    border-bottom:1px solid var(--primary-color);
}
.table-title{
    background-color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
    padding: 8px 10px;
}
.table-keys{
    font-weight: bold;
}

.data-cells{
    font-size: 14px;
}
.cell{
    padding: 8px 15px;
    width: 50%;
    border: none;
    outline: none;
    background-color: transparent;
}

.cell2{
    padding: 8px 15px;
    width: 16%;
    border: none;
    background-color: transparent;
    text-align: center;
}
.value2{
    border-left: 1px solid var(--primary-color);
}

.value{
    border-left: 1px solid var(--primary-color);
    height: 18px;
    outline: none;
    width: 70%;   
}

.pointing{
    transition: 200ms ease-in-out;
}
.pointing:hover{
    background-color: var(--primary-color);
}