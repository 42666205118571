.files_container{
	height: calc(100vh - 125px);
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}

.folder{
	background-color: var(--btn-color);
	height: 150px;
	width: 150px;
	border-radius: 8px;
	flex-direction: column;
	gap: 10px;
	cursor: pointer;
	transition: 280ms ease-in-out;
	border: 1px solid var(--primary-color);
	flex-grow: 1;
	flex-basis: 200;
}
.folder:hover{
	background-color: var(--primary-color);
}

.f_name{
	text-align: center;
	align-self: center !important;
	width: 95%;
    word-wrap: break-word;
}

.f_icon{
	font-size: 1.5rem;
}