.head{
	width: 100%;
}

.folder_main{
	width: 100%;
	height: calc(100vh - 125px);
}
.right_margin{
	margin-left: auto;
}

.files2_container{
	margin-top: 15px;
	flex-wrap: wrap;
	gap: 15px;
}

.uploader{
	position: relative;
}

.uploader_input{
	width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}