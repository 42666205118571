.start{
	align-self: flex-start;
}
.upspace{
	margin-top:15px !important;
}
.main_container{
	margin:60px 90px;
	gap: 20px;
}

.top-logo{
	height: auto;
	width: 275px;
}
h4{
	margin: 0;
	align-self: flex-start;
}
h3{
	margin: 0;
}
p{
	margin: 0;
}
.info{
	border: 1px solid var(--primary-color);
	padding: 10px;
	border-radius: var(--radius);
}
.informations{
	margin-right: auto;
	padding-left: 10px;
}
.heading{
	background-color: #073163;
	padding: 10px;
	color: white;
}
.text{
	font-size: 13px;
	align-self: flex-start;
}

.notice{
	border: 1px solid var(--primary-color);
	padding: 10px;
	border-radius: var(--radius);
	margin: 0 10px 10px 10px ;
}

.inbtwnspace{
	margin: 10px 0px;
}
.marc{
	border:1px solid black;
	border-radius: var(--radius);
	padding: 1px 6px;
	color: transparent;
}
.sign-box{
	border: 1px solid black;
	width: calc(100% - 400px);
	height: 100px;
}
@media screen and (max-width: 850px){
	.main_container{
		margin: 10px 20px;
	}
}

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
}