.back-drop{
	background-color: #00000082;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	z-index: -1;
}
.show-backdrop{
	z-index: 5;
	opacity: 1;
}

.modal{
	background-color: white;
	position: fixed;
	left: 50%;
	transform: translate(-50%, -50%);
	height: auto;
	width: 400px;
	flex-direction: column;
	justify-content: flex-start;
	top: 1000px;
	transition: 500ms ease-in-out;
	z-index: 6;
	border-radius: 5px;
	padding: 10px;
	border:2px solid var(--primary-color);
	gap:15px;
}

.show_modal{
	top: 50%;
}

.cross-icon{
	background-color: var(--primary-color);
	padding: 5px;
	font-size: 1.5rem;
	border-radius: 4px;
	cursor: pointer;
	align-self: flex-end;
	transition: 200ms ease-in-out;
	opacity: 0.8;
}

.cross-icon:hover{
	background-color: var(--primary-color);
	opacity: 1;
}

.search{
	gap: 10px;
	width: calc(100% - 20px);
	padding: 8px 10px;
	border-radius: 5px;
	border: 1px solid var(--primary-color);
}
.search-input{
	padding: 5px 2px;
	background-color: transparent;
	border: none;
	color: var(--black-color);
	outline: none;
	width: 90%;
}
.icon_m{
	font-size: 1.4rem;
}
.open_uploader{
	width: calc(100% - 24px);
	height: 200px;
}

.upload_on{
	flex-direction: column;
}

.wait{
	color:orange;
}

.success{
	color: green;
}

.err{
	color: red;
}