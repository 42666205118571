
.float-loading{
	position: absolute;
	top: -10px;
	left: -10px;
	background-color: #777777ba;
	height: calc(100% + 20px);
	width: calc(100% + 20px);
}
.loading{
	font-size: 1.7rem;
	-webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}


@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}