.main_msg{
	position: absolute;
	bottom: 10px;
	background-color:var(--primary-color) ;
	padding: 15px 20px;
	border: 1px solid black;
	border-radius: var(--radius);
	box-shadow: rgb(165 165 165) 0px 4px 12px;
	transition: 250ms ease-in-out;
	gap:15px;
	z-index: 10;
}